import axios from '@axios'

export default function EventsServices() {
    const getLive = async (id) => {
      const api = await axios.get(`/events/lives/get-live/${id}`)
      return api
    }

    return {
        getLive
    }
}